export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
	[_ in K]?: never
}
export type Incremental<T> =
	| T
	| { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string }
	String: { input: string; output: string }
	Boolean: { input: boolean; output: boolean }
	Int: { input: number; output: number }
	Float: { input: number; output: number }
	Instant: { input: any; output: any }
	JsonNode: { input: any; output: any }
	LocalDate: { input: any; output: any }
	LocalTime: { input: any; output: any }
}

export type AvailablePvComponentsRequest = {
	activeOnly?: InputMaybe<Scalars['Boolean']['input']>
	battery?: InputMaybe<UidRequest>
	inverter?: InputMaybe<UidRequest>
	organization: UidRequest
}

export type AvailablePvComponentsResponse = {
	__typename?: 'AvailablePVComponentsResponse'
	batteries?: Maybe<Array<FveComponent>>
	inverters?: Maybe<Array<FveComponent>>
	panels?: Maybe<Array<FveComponent>>
}

export enum CatalogItemAttributeValueType {
	Bool = 'BOOL',
	Multiselect = 'MULTISELECT',
	Number = 'NUMBER',
	Range = 'RANGE',
	Select = 'SELECT',
	Text = 'TEXT'
}

export enum CoveragePlan {
	Maximum = 'MAXIMUM',
	Optimum = 'OPTIMUM'
}

export type CsHvacRequest = {
	houseHeatLoss: Scalars['Float']['input']
	location: Scalars['String']['input']
	waterConsumption: Scalars['Float']['input']
	withWater: Scalars['Boolean']['input']
}

export type CsHvacResponse = {
	__typename?: 'CsHvacResponse'
	efficiency45: Scalars['Float']['output']
	efficiency55: Scalars['Float']['output']
	grant: Scalars['Float']['output']
	price: Scalars['Float']['output']
}

export enum CurrencyCode {
	Czk = 'CZK',
	Eur = 'EUR',
	Gbp = 'GBP',
	Pln = 'PLN',
	Usd = 'USD'
}

export type DefinedPvComponent = {
	amount: Scalars['Int']['input']
	package: UidRequest
}

export type DefinedPvComponents = {
	battery?: InputMaybe<DefinedPvComponent>
	inverter?: InputMaybe<DefinedPvComponent>
	optimalizer?: InputMaybe<DefinedPvComponent>
	panel?: InputMaybe<DefinedPvComponent>
	switchboard?: InputMaybe<DefinedPvComponent>
	wallbox?: InputMaybe<DefinedPvComponent>
	wattrouter?: InputMaybe<DefinedPvComponent>
}

export type DeviceTypeOptions = {
	__typename?: 'DeviceTypeOptions'
	brandName: Scalars['String']['output']
	brandPriority: Scalars['Int']['output']
	noWater?: Maybe<PumpResultData>
	withWater?: Maybe<PumpResultData>
}

export type EnergyInvoices = {
	coalInvoice?: InputMaybe<InvoiceCoal>
	electroInvoice?: InputMaybe<InvoiceGasOrElectro>
	gasInvoice?: InputMaybe<InvoiceGasOrElectro>
}

export type FveAttribute = {
	__typename?: 'FveAttribute'
	attributeType: FveAttributeType
	title: Scalars['String']['output']
	unit: Scalars['String']['output']
	value: Scalars['String']['output']
}

export type FveAttributeType = {
	__typename?: 'FveAttributeType'
	id: Scalars['ID']['output']
	title: Scalars['String']['output']
	valueType?: Maybe<CatalogItemAttributeValueType>
}

export type FveComponent = {
	__typename?: 'FveComponent'
	attributes: Array<FveAttribute>
	brand?: Maybe<Scalars['String']['output']>
	componentType: FveComponentType
	description: Scalars['String']['output']
	files: Array<WaPackageFileDto>
	images: Array<WaPackageFileDto>
	max?: Maybe<Scalars['Int']['output']>
	min?: Maybe<Scalars['Int']['output']>
	name: Scalars['String']['output']
	packageId: Scalars['ID']['output']
	packageUid: Scalars['String']['output']
	price: Scalars['Float']['output']
	priceMaterial: Scalars['Float']['output']
	priceWork: Scalars['Float']['output']
	quantity: Scalars['Int']['output']
	thumbnail?: Maybe<Scalars['String']['output']>
}

export enum FveComponentType {
	AcTrasa = 'AC_TRASA',
	Battery = 'BATTERY',
	DcTrasa = 'DC_TRASA',
	Hoist = 'HOIST',
	Inverter = 'INVERTER',
	KonstrukceBasic = 'KONSTRUKCE_BASIC',
	Meter = 'METER',
	Optimizer = 'OPTIMIZER',
	Panel = 'PANEL',
	Roof = 'ROOF',
	Switchboard = 'SWITCHBOARD',
	Wallbox = 'WALLBOX',
	Wattrouter = 'WATTROUTER'
}

export type FveConsumptionData = {
	__typename?: 'FveConsumptionData'
	co2SavingsWithFve?: Maybe<Scalars['Float']['output']>
	consumption?: Maybe<Scalars['Float']['output']>
	consumptionAfterFve?: Maybe<Scalars['Float']['output']>
	energyPrices: PricesGrowthResponse
	monthData: Array<MonthDataResponse>
	seasonData: Array<SeasonDataResponse>
	surplus?: Maybe<Scalars['Float']['output']>
	totalFveProduction?: Maybe<Scalars['Float']['output']>
	totalMwhSavings?: Maybe<Scalars['Float']['output']>
}

export enum FveRoofType {
	CeramicTile = 'CERAMIC_TILE',
	FlatRoof = 'FLAT_ROOF',
	Ground = 'GROUND',
	RoofShingle = 'ROOF_SHINGLE',
	SeamSheet = 'SEAM_SHEET',
	SteelTile = 'STEEL_TILE',
	TrapezoidalSheet = 'TRAPEZOIDAL_SHEET'
}

export type FveVariant = {
	__typename?: 'FveVariant'
	batteryCapacity: Scalars['Float']['output']
	components: Array<FveComponent>
	fveConsumptionData: FveConsumptionData
	fvePower: Scalars['Float']['output']
	fveProduction: Scalars['Float']['output']
	/**  Deprecated. Use subsidy instead.  */
	grant: Scalars['Float']['output']
	priceMaterial: Scalars['Float']['output']
	priceWork: Scalars['Float']['output']
	roi: Scalars['Int']['output']
	subsidy: Scalars['Float']['output']
	subsidyUpTo?: Maybe<Scalars['Float']['output']>
	totalPrice: Scalars['Float']['output']
	variantCriterium: VariantCriterium
}

export enum HeatPumpSystem {
	AirToAir = 'AIR_TO_AIR',
	AirToWater = 'AIR_TO_WATER',
	GroundToWater = 'GROUND_TO_WATER',
	WaterToWater = 'WATER_TO_WATER'
}

export enum HeatPumpType {
	Monoblok = 'MONOBLOK',
	Split = 'SPLIT'
}

export type HouseHeatLossRequest = {
	/**  Also needs roof and window. Or enter isolationLevel.  */
	facade?: InputMaybe<Scalars['Int']['input']>
	floorArea?: InputMaybe<Scalars['Int']['input']>
	/**  Required inside temperature. 22 °C is default.  */
	insideTemperature?: InputMaybe<Scalars['Int']['input']>
	/**  Enter isolationLevel or roof+facade+window.  */
	isolationLevel?: InputMaybe<IsoLevel>
	organizationId?: InputMaybe<Scalars['ID']['input']>
	/**  Coldest average outside temperature during a heating season. Common value will be used if both this and zip are empty.  */
	outsideTemperature?: InputMaybe<Scalars['Int']['input']>
	/**  Mechanical ventilation  */
	recuperation?: InputMaybe<Scalars['Boolean']['input']>
	/**  Also needs facade and window. Or enter isolationLevel.  */
	roof?: InputMaybe<Scalars['Int']['input']>
	/**  Also needs facade and roof. Or enter isolationLevel.  */
	window?: InputMaybe<WindowIsolation>
	/**  Is used to compute average outside temperature. Or enter precise outsideTemperature value.  */
	zip?: InputMaybe<Scalars['String']['input']>
}

export type HouseHeatLossResponse = {
	__typename?: 'HouseHeatLossResponse'
	heatLoss: Scalars['Float']['output']
}

export enum HouseHeatType {
	Coal = 'COAL',
	CoalIIi = 'COAL_I_II',
	Electro = 'ELECTRO',
	Gas = 'GAS',
	GasCondensing = 'GAS_CONDENSING',
	GasOld = 'GAS_OLD',
	Heatpump = 'HEATPUMP',
	Oil = 'OIL'
}

export type HouseIsolationLevel = {
	facade: Scalars['Int']['input']
	recuperation?: InputMaybe<Scalars['Boolean']['input']>
	roof: Scalars['Int']['input']
	veryBadHouse: Scalars['Boolean']['input']
	window: WindowIsolation
}

export type HvacFromQueryResponse = {
	__typename?: 'HvacFromQueryResponse'
	co2?: Maybe<Scalars['Float']['output']>
	deviceTypes: Array<Maybe<DeviceTypeOptions>>
	grantWithWater: Scalars['Float']['output']
	grantWithoutWater: Scalars['Float']['output']
	houseHeatLoss: Scalars['Float']['output']
	totalYearlyElConsumption?: Maybe<Scalars['Float']['output']>
	totalYearlyEnergyConsumption?: Maybe<Scalars['Float']['output']>
	yourAnnuallyMwh: Scalars['Float']['output']
	yourElPricePerKwh: Scalars['Float']['output']
	yourTotalYearlyCosts: Scalars['Float']['output']
}

export type InvoiceCoal = {
	annualKg: Scalars['Int']['input']
	annualPrice: Scalars['Float']['input']
	fuel?: InputMaybe<SolidFuel>
}

export type InvoiceGasOrElectro = {
	annualKwh: Scalars['Float']['input']
	annualPrice: Scalars['Float']['input']
	priceFixedForNextYear: Scalars['Boolean']['input']
}

export enum IsoLevel {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM',
	No = 'NO'
}

export enum LanguageCode {
	Cs = 'CS',
	De = 'DE',
	En = 'EN',
	It = 'IT',
	Pl = 'PL',
	Sk = 'SK'
}

export enum Month {
	April = 'APRIL',
	August = 'AUGUST',
	December = 'DECEMBER',
	February = 'FEBRUARY',
	January = 'JANUARY',
	July = 'JULY',
	June = 'JUNE',
	March = 'MARCH',
	May = 'MAY',
	November = 'NOVEMBER',
	October = 'OCTOBER',
	September = 'SEPTEMBER'
}

export type MonthDataResponse = {
	__typename?: 'MonthDataResponse'
	consumption: Scalars['Float']['output']
	consumptionWithFve: Scalars['Float']['output']
	energyProduction: Scalars['Float']['output']
	fveCoverage: Scalars['Float']['output']
	moneySavings: Scalars['Float']['output']
	month: Month
	monthOrd: Scalars['Int']['output']
	surplus: Scalars['Float']['output']
}

export type Mutation = {
	__typename?: 'Mutation'
	productFeedPrepare?: Maybe<Scalars['Boolean']['output']>
}

export type PanelArea = {
	angle: Scalars['Int']['input']
	heightInMillimeters: Scalars['Int']['input']
	orientation: Scalars['Int']['input']
	widthInMillimeters: Scalars['Int']['input']
}

export type PanelString = {
	panelCount: Scalars['Int']['input']
	roofAngle: Scalars['Int']['input']
	roofOrientation: Scalars['Int']['input']
}

export type PanelStrings = {
	stringOne: PanelString
	stringTwo?: InputMaybe<PanelString>
}

export type PricesGrowthResponse = {
	__typename?: 'PricesGrowthResponse'
	energyBuyout: Scalars['Float']['output']
	/**  future payments for electricity every 5 years for 30 years  */
	paymentsNow: Array<Maybe<Scalars['Int']['output']>>
	/**  future payments for electricity after FVE savings every 5 years for 30 years  */
	paymentsWithFve: Array<Maybe<Scalars['Int']['output']>>
	priceGrow: Scalars['Int']['output']
}

export enum PumpPosition {
	Corner = 'CORNER',
	Ground = 'GROUND',
	Wall = 'WALL'
}

export type PumpResultData = {
	__typename?: 'PumpResultData'
	annualConsumption: Scalars['Float']['output']
	annualMoneySavings: Scalars['Float']['output']
	bivalentPoint?: Maybe<Scalars['Int']['output']>
	catalogId?: Maybe<Scalars['ID']['output']>
	catalogPackage?: Maybe<WaPackageDto>
	catalogPackageTotalPrice?: Maybe<Scalars['Float']['output']>
	co2Savings?: Maybe<Scalars['Float']['output']>
	description?: Maybe<Scalars['String']['output']>
	files?: Maybe<Array<Scalars['String']['output']>>
	futureCo2: Scalars['Float']['output']
	/**  Deprecated. Use subsidy instead.  */
	germanGrant: Scalars['Float']['output']
	houseAnnualElectricityConsumption: Scalars['Float']['output']
	images?: Maybe<Array<Scalars['String']['output']>>
	mwhSavings?: Maybe<Scalars['Float']['output']>
	name?: Maybe<Scalars['String']['output']>
	/**  Total price with VAT with subsidy discount applied.  */
	priceWithSubsidy: Scalars['Float']['output']
	purchasePrice?: Maybe<Scalars['Float']['output']>
	roi?: Maybe<Scalars['Float']['output']>
	subsidy: Scalars['Float']['output']
	subsidyUpTo?: Maybe<Scalars['Float']['output']>
	temperatureCost: Array<TemperatureCost>
	thumbnail?: Maybe<Scalars['String']['output']>
	withWater?: Maybe<Scalars['Boolean']['output']>
}

export type Query = {
	__typename?: 'Query'
	availablePVComponents: AvailablePvComponentsResponse
	houseHeatLoss?: Maybe<HouseHeatLossResponse>
	subsidyBonusLocation: Scalars['Boolean']['output']
	subsidyHvac: Scalars['Float']['output']
	subsidyPv: Scalars['Float']['output']
	woltAdvisorCatalogPackageSummary: WaPackageDto
	woltAdvisorCatalogPackages: Array<Maybe<WaPackageDto>>
	woltAdvisorFve: WoltAdvisorFveResponse
	woltAdvisorHvacFromQuery: HvacFromQueryResponse
	woltAdvisorHvacFromQueryCS?: Maybe<CsHvacResponse>
}

export type QueryAvailablePvComponentsArgs = {
	request: AvailablePvComponentsRequest
}

export type QueryHouseHeatLossArgs = {
	request?: InputMaybe<HouseHeatLossRequest>
}

export type QuerySubsidyBonusLocationArgs = {
	request: SubsidyBonusLocationRequest
}

export type QuerySubsidyHvacArgs = {
	request: SubsidyHvacRequest
}

export type QuerySubsidyPvArgs = {
	request: SubsidyPvRequest
}

export type QueryWoltAdvisorCatalogPackageSummaryArgs = {
	request: WoltAdvisorCatalogPackageSummaryRequest
}

export type QueryWoltAdvisorCatalogPackagesArgs = {
	request?: InputMaybe<WoltAdvisorCatalogPackagesRequest>
}

export type QueryWoltAdvisorFveArgs = {
	request: WoltAdvisorFveRequest
}

export type QueryWoltAdvisorHvacFromQueryArgs = {
	request: WoltAdvisorFromQueryRequest
}

export type QueryWoltAdvisorHvacFromQueryCsArgs = {
	request: CsHvacRequest
}

export enum RefrigerantType {
	R32 = 'R32',
	R290 = 'R290',
	R404A = 'R404A',
	R407C = 'R407C',
	R410A = 'R410A'
}

export enum ResourceType {
	Document = 'DOCUMENT',
	Image = 'IMAGE',
	Photo = 'PHOTO',
	Video = 'VIDEO'
}

export enum Season {
	Autumn = 'AUTUMN',
	Spring = 'SPRING',
	Summer = 'SUMMER',
	Winter = 'WINTER'
}

export type SeasonDataResponse = {
	__typename?: 'SeasonDataResponse'
	fveCoverage: Scalars['Float']['output']
	season: Season
}

export enum SolidFuel {
	BlackCoal = 'BLACK_COAL',
	BrownCoal = 'BROWN_COAL',
	Koks = 'KOKS'
}

export type SubsidyBonusLocationRequest = {
	city: Scalars['String']['input']
	region: Scalars['String']['input']
	zip: Scalars['String']['input']
}

export type SubsidyHvacRequest = {
	city?: InputMaybe<Scalars['String']['input']>
	finalPrice: Scalars['Float']['input']
	floorHeating?: InputMaybe<Scalars['Boolean']['input']>
	heatPumpSystem?: InputMaybe<HeatPumpSystem>
	heatType: HouseHeatType
	organizationId: Scalars['ID']['input']
	refrigerantType?: InputMaybe<RefrigerantType>
	region?: InputMaybe<Scalars['String']['input']>
	withWater: Scalars['Boolean']['input']
	zip?: InputMaybe<Scalars['String']['input']>
}

export type SubsidyPvRequest = {
	batteryCapacity: Scalars['Float']['input']
	city?: InputMaybe<Scalars['String']['input']>
	fvePower: Scalars['Float']['input']
	insulation: Scalars['Boolean']['input']
	organizationId: Scalars['ID']['input']
	region?: InputMaybe<Scalars['String']['input']>
	totalPrice: Scalars['Float']['input']
	wallBox: Scalars['Boolean']['input']
	waterHeating: Scalars['Boolean']['input']
	zip?: InputMaybe<Scalars['String']['input']>
}

export type TemperatureCost = {
	__typename?: 'TemperatureCost'
	cost: Scalars['Float']['output']
	temp: Scalars['Int']['output']
}

export type UidRequest = {
	id?: InputMaybe<Scalars['ID']['input']>
	uid?: InputMaybe<Scalars['String']['input']>
}

export enum VariantCriterium {
	BestMatch = 'BEST_MATCH',
	/**  not used right now  */
	BestPerformance = 'BEST_PERFORMANCE',
	Cheapest = 'CHEAPEST',
	Common = 'COMMON',
	Large = 'LARGE',
	NoVariants = 'NO_VARIANTS',
	/**  not used right now  */
	Recommended = 'RECOMMENDED',
	Small = 'SMALL'
}

export type WaPackageDto = {
	__typename?: 'WaPackageDto'
	accumulationVessel: Scalars['Boolean']['output']
	accumulationVesselCapacity?: Maybe<Scalars['Int']['output']>
	accumulationVolume: Scalars['Int']['output']
	brandName: Scalars['String']['output']
	catalogId: Scalars['ID']['output']
	catalogTotalPrice: Scalars['Float']['output']
	catalogTotalPriceWithVat: Scalars['Float']['output']
	catalogUid: Scalars['String']['output']
	combinatoryBonus: Scalars['Boolean']['output']
	compressor?: Maybe<Scalars['String']['output']>
	compressorCount?: Maybe<Scalars['Int']['output']>
	condition400l: Scalars['Boolean']['output']
	coolingOption?: Maybe<Scalars['Boolean']['output']>
	coolingPerformance?: Maybe<Scalars['Float']['output']>
	countryOfOrigin?: Maybe<Scalars['String']['output']>
	currencyCode: CurrencyCode
	description: Scalars['String']['output']
	descriptionProduct?: Maybe<Scalars['String']['output']>
	deviceSubtypeId: Scalars['ID']['output']
	discount?: Maybe<Scalars['Float']['output']>
	discountWithVat?: Maybe<Scalars['Float']['output']>
	dynamicPrice?: Maybe<Scalars['Float']['output']>
	dynamicPriceWithVat?: Maybe<Scalars['Float']['output']>
	energyEfficiencyRating?: Maybe<Scalars['String']['output']>
	files: Array<WaPackageFileDto>
	floorHeatingCop?: Maybe<Scalars['Float']['output']>
	floorHeatingPerformance?: Maybe<Scalars['Float']['output']>
	gwpCo2?: Maybe<Scalars['Float']['output']>
	gwpRefrigerant?: Maybe<Scalars['Float']['output']>
	heatPumpSystem?: Maybe<HeatPumpSystem>
	heatPumpType?: Maybe<HeatPumpType>
	images: Array<WaPackageFileDto>
	inputWaterTemperature?: Maybe<Scalars['Float']['output']>
	internetConnection?: Maybe<Scalars['Boolean']['output']>
	inverter?: Maybe<Scalars['String']['output']>
	languageCode: LanguageCode
	mainItemDepth?: Maybe<Scalars['Float']['output']>
	mainItemHeight?: Maybe<Scalars['Float']['output']>
	mainItemStock?: Maybe<Scalars['Float']['output']>
	mainItemTotalPrice?: Maybe<Scalars['Float']['output']>
	mainItemTotalPriceWithVat?: Maybe<Scalars['Float']['output']>
	mainItemWeight?: Maybe<Scalars['Float']['output']>
	mainItemWidth?: Maybe<Scalars['Float']['output']>
	materialTotalPrice?: Maybe<Scalars['Float']['output']>
	materialTotalPriceWithVat?: Maybe<Scalars['Float']['output']>
	maxHeatLoss?: Maybe<Scalars['String']['output']>
	noiseLevel?: Maybe<Scalars['Float']['output']>
	numberOfPhases?: Maybe<Scalars['Int']['output']>
	operatingRange?: Maybe<Scalars['String']['output']>
	organizationId: Scalars['ID']['output']
	originCountry: Scalars['String']['output']
	priority: Scalars['Int']['output']
	radiatorHeatingCop?: Maybe<Scalars['Float']['output']>
	radiatorHeatingPerformance?: Maybe<Scalars['Float']['output']>
	refrigerantControl?: Maybe<Scalars['Int']['output']>
	refrigerantType?: Maybe<Scalars['String']['output']>
	refrigerantVolume?: Maybe<Scalars['Float']['output']>
	scop?: Maybe<Scalars['Float']['output']>
	sgReady?: Maybe<Scalars['Boolean']['output']>
	siblingPackage?: Maybe<WaSiblingPackageDto>
	slugId: Scalars['String']['output']
	supplierId?: Maybe<Scalars['ID']['output']>
	supplierName?: Maybe<Scalars['String']['output']>
	thumbnail?: Maybe<Scalars['String']['output']>
	title: Scalars['String']['output']
	waterTankCapacity?: Maybe<Scalars['Int']['output']>
	withWater: Scalars['Boolean']['output']
	workTotalPrice?: Maybe<Scalars['Float']['output']>
	workTotalPriceWithVat?: Maybe<Scalars['Float']['output']>
	yearlyCheck?: Maybe<Scalars['Boolean']['output']>
}

export type WaPackageFileDto = {
	__typename?: 'WaPackageFileDto'
	fileApiUid: Scalars['String']['output']
	fileName?: Maybe<Scalars['String']['output']>
	mimeType: Scalars['String']['output']
	name: Scalars['String']['output']
	size: Scalars['Int']['output']
	tags: Array<Scalars['String']['output']>
	url: Scalars['String']['output']
}

export type WaSiblingPackageDto = {
	__typename?: 'WaSiblingPackageDto'
	catalogId: Scalars['ID']['output']
	catalogUid: Scalars['String']['output']
	slugId: Scalars['String']['output']
	withWater: Scalars['Boolean']['output']
}

export enum WaterSystem {
	All = 'ALL',
	NoWater = 'NO_WATER',
	WithWater = 'WITH_WATER'
}

export enum WindowIsolation {
	Double = 'DOUBLE',
	Triple = 'TRIPLE',
	Wooden = 'WOODEN'
}

export type WoltAdvisorCatalogPackageSummaryRequest = {
	catalogId?: InputMaybe<Scalars['ID']['input']>
	catalogUid?: InputMaybe<Scalars['String']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
}

export type WoltAdvisorCatalogPackagesRequest = {
	organizationId?: InputMaybe<Scalars['ID']['input']>
}

export type WoltAdvisorFromQueryRequest = {
	/**  Not used right now.  */
	affiliateCode?: InputMaybe<Scalars['String']['input']>
	airCondition: Scalars['Boolean']['input']
	cityName?: InputMaybe<Scalars['String']['input']>
	coalAutomatic?: InputMaybe<Scalars['Boolean']['input']>
	colors?: InputMaybe<Array<UidRequest>>
	countryOfOrigin?: InputMaybe<Scalars['String']['input']>
	customIsolation?: InputMaybe<HouseIsolationLevel>
	definedPackages?: InputMaybe<Array<UidRequest>>
	depth?: InputMaybe<Scalars['Float']['input']>
	energyPriceInvoices?: InputMaybe<EnergyInvoices>
	filterResult?: InputMaybe<Scalars['Boolean']['input']>
	filterResultWaterSystem?: InputMaybe<WaterSystem>
	floorSquare: Scalars['Int']['input']
	height?: InputMaybe<Scalars['Float']['input']>
	houseHeatLoss?: InputMaybe<Scalars['Float']['input']>
	houseHeatType: HouseHeatType
	inStock?: InputMaybe<Scalars['Boolean']['input']>
	insideTemp?: InputMaybe<Scalars['Int']['input']>
	isoLevel?: InputMaybe<IsoLevel>
	limit?: InputMaybe<Scalars['Int']['input']>
	noiseLevel?: InputMaybe<Scalars['Float']['input']>
	organization: UidRequest
	peopleCount: Scalars['Int']['input']
	phaseCount?: InputMaybe<Scalars['Int']['input']>
	psc?: InputMaybe<Scalars['String']['input']>
	pumpPosition?: InputMaybe<PumpPosition>
	refrigerantType?: InputMaybe<RefrigerantType>
	regionName: Scalars['String']['input']
	solidFuel?: InputMaybe<SolidFuel>
	supplierTitle?: InputMaybe<Scalars['String']['input']>
	supplierTitles?: InputMaybe<Array<Scalars['String']['input']>>
	waterHeatingWithHouseHeatSource: Scalars['Boolean']['input']
	waterTempFortyFive?: InputMaybe<Scalars['Boolean']['input']>
	width?: InputMaybe<Scalars['Float']['input']>
}

export type WoltAdvisorFveRequest = {
	/**  Not used right now.  */
	affiliateCode?: InputMaybe<Scalars['String']['input']>
	airCondition?: InputMaybe<Scalars['Boolean']['input']>
	/**  Annual electricity consumption in MWh.  */
	annualElectricityConsumption?: InputMaybe<Scalars['Float']['input']>
	city?: InputMaybe<Scalars['String']['input']>
	coalAutomatic?: InputMaybe<Scalars['Boolean']['input']>
	/**  Not used right now.  */
	coveragePlan?: InputMaybe<CoveragePlan>
	customIsolation?: InputMaybe<HouseIsolationLevel>
	definedComponents?: InputMaybe<DefinedPvComponents>
	energyInvoices?: InputMaybe<EnergyInvoices>
	floorArea?: InputMaybe<Scalars['Int']['input']>
	fveRoofType?: InputMaybe<FveRoofType>
	houseHeatLoss?: InputMaybe<Scalars['Float']['input']>
	houseHeatType: HouseHeatType
	insideTemperature?: InputMaybe<Scalars['Int']['input']>
	isoLevel?: InputMaybe<IsoLevel>
	monthlyElectricityCost?: InputMaybe<Scalars['Float']['input']>
	organization?: InputMaybe<UidRequest>
	otherResultsLimit?: InputMaybe<Scalars['Int']['input']>
	panelArea?: InputMaybe<PanelArea>
	panelStrings?: InputMaybe<PanelStrings>
	peopleCount?: InputMaybe<Scalars['Int']['input']>
	phaseCount?: InputMaybe<Scalars['Int']['input']>
	region?: InputMaybe<Scalars['String']['input']>
	/**  Distance of the bottom part of the roof from the ground in whole meters.  */
	roofHeight?: InputMaybe<Scalars['Int']['input']>
	solidFuel?: InputMaybe<SolidFuel>
	/**  True - using heat source, false - using electric boiler  */
	waterHeatingWithHouseHeatSource?: InputMaybe<Scalars['Boolean']['input']>
	zip?: InputMaybe<Scalars['String']['input']>
}

export type WoltAdvisorFveResponse = {
	__typename?: 'WoltAdvisorFveResponse'
	annualElectricityConsumptionMWh: Scalars['Float']['output']
	electricityPricePerKWh: Scalars['Float']['output']
	monthlyElectricityCost: Scalars['Float']['output']
	variants: Array<FveVariant>
}
